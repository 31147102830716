const CommonEndpoints = {
	getViewList(data: any) {
		return {
			url: 'uam/view_access',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	resetPassword(data: any) {
		return {
			url: 'uam/reset_password',
			data: data,
		};
	},
	changePassword(data: any) {
		return {
			url: 'governance/change_password',
			data: data,
		};
	},
	getGlobalSettings(data: any) {
		return {
			url: 'marketingnode/globalSettings',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	getLogoUrl(data: any) {
		return {
			url: 'sharedfeature/get_logo',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	getConnectedCRM(data: any) {
		return {
			url: 'auditsalesforce/crm_connected',
			data: data?.reqBody,
			headers: data?.headers,
		};
	},
	getSavedFilters(data?: any) {
		return {
			url: 'hubspotreports/metrics_hub_filters',
			data: data?.reqBody,
			headers: data?.headers,
		};
	},
};

export default CommonEndpoints;
