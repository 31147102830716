import React from 'react';

const filterIconSvg = (props) => {
	return (
		<div>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill={props.fill}
				xmlns='http://www.w3.org/2000/svg'
				{...props}
			>
				<g clip-path='url(#clip0_541_317)'>
					<path
						d='M14.583 25C14.3577 25 14.1384 24.9269 13.958 24.7917L9.79138 21.6667C9.66201 21.5696 9.55701 21.4438 9.48469 21.2992C9.41237 21.1545 9.37472 20.995 9.37472 20.8333V14.9792L2.06638 6.75729C1.54734 6.17175 1.20844 5.44877 1.09042 4.67524C0.972403 3.90172 1.08028 3.11057 1.40109 2.39689C1.72191 1.6832 2.242 1.07735 2.89886 0.652152C3.55573 0.226951 4.32141 0.000494186 5.10388 0L19.8956 0C20.678 0.000917761 21.4435 0.227749 22.1 0.653236C22.7566 1.07872 23.2764 1.68476 23.5969 2.39853C23.9173 3.11229 24.0249 3.90341 23.9066 4.67682C23.7883 5.45023 23.4491 6.17302 22.9299 6.75833L15.6247 14.9792V23.9583C15.6247 24.2346 15.515 24.4996 15.3196 24.6949C15.1243 24.8903 14.8593 25 14.583 25ZM11.458 20.3125L13.5414 21.875V14.5833C13.5416 14.3283 13.6354 14.0822 13.8049 13.8917L21.3758 5.37396C21.6282 5.08863 21.7929 4.7365 21.8502 4.35985C21.9074 3.98319 21.8547 3.59802 21.6984 3.25058C21.5421 2.90314 21.2889 2.60818 20.9691 2.40113C20.6493 2.19408 20.2765 2.08373 19.8956 2.08333H5.10388C4.7231 2.08391 4.35057 2.19432 4.03097 2.40132C3.71137 2.60832 3.45826 2.90312 3.30201 3.25037C3.14577 3.59761 3.093 3.98256 3.15006 4.35904C3.20711 4.73552 3.37156 5.08756 3.62367 5.37292L11.1955 13.8917C11.3647 14.0823 11.4581 14.3284 11.458 14.5833V20.3125Z'
						fill={props.fill}
					/>
				</g>
				<defs>
					<clipPath id='clip0_541_317'>
						<rect width='25' height='25' fill={props.fill} />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default filterIconSvg;
