import { useContext, useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import InsightsDrawer from './InsightsDrawer';
import ToolTipOnText from '../Tooltip/Tooltip';
import * as actions from '../../AppContext/actions/actions';
import { useAppContext } from '../../AppContext/App.context';
import InsightsEndPoints from './InsightsEndPoints';
import useApiService from '../../services/api.service';
import useToast from '../Toast/hooks/useToast';
import useLoader from '../../hooks/useLoader';
import InsightsIcon from '../../assets/Images/insights';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
const InsightsButton = () => {
	const [showQuestions, setShowQuestions] = useState(false);
	const [appData, dispatch]: any = useAppContext();
	const APIService = useApiService();
	const toaster: any = useToast();
	const loader: any = useLoader();
	const theme: any = useTheme();
	const [showDrawer, setShowDrawer] = useState(false);
	const sidebarEl = document.getElementById('digg_sidebar');

	useEffect(() => {
		getInsightsQuestions();
	}, []);
	const getInsightsQuestions = async () => {
		if (appData.insightsQuestions && !appData.insightsQuestions.length) {
			const reqData = {
				request: {},
			};
			// loader.showLoader();
			return await APIService.get(
				InsightsEndPoints.getInsightsQuestions(reqData)
			)
				.then((res: any) => {
					// loader.hideLoader();
					dispatch(actions.setInsightsQuestionsHandler(res.data));
				})
				.catch((err: any) => {
					// loader.hideLoader();
					toaster.addToast({
						message: err.response || 'Something went wrong',
						timeout: 2000,
						type: 'error',
					});
				});
		}
	};
	const data = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
	};
	return (
		<>
			<div
				style={{
					display: 'inherit',
					cursor: 'pointer',
				}}
			>
				<ToolTipOnText title='Insights'>
					{/* <Button
						style={{ border: 'none' }}
						//className='pinIcon bookmarkButtons'
						type='button'
						btnText=''
						variant='outlined'
						onClick={() => dispatch(actions.questionsDrawerHandler(true))}
						startIcon={InsightsIcon}
					/> */}
					{/* <div onClick={() => dispatch(actions.questionsDrawerHandler(true))}>
						<img
							src={InsightsIcon}
							alt='Insights'
							style={{ position: 'relative', top: '17px', height: '23px' }}
						/>
					</div> */}

					{/* <Button className='pinIcon bookmarkButtons' variant='contained' btnText={''} type='button'>
						<img src={InsightsIcon} alt='Insights' width={'20px'} />
					</Button> */}

					<Button
						className='insightsButton'
						btnText={''}
						type='button'
						variant='contained'
						color='secondary'
						style={{
							color: theme.palette.text.tertiary,
							backgroundColor: 'transparent',
							// padding: 0,
							minWidth: '0px',
							padding: '10px 15px',
							marginRight: '15px',
						}}
						startIcon={
							// <Avatar style={{ fontSize: '25px' }} src={InsightsIcon} />
							<InsightsIcon {...data} />
						}
						onClick={() => {
							setShowDrawer(true);
							sidebarEl?.classList.add('Sidebar-backdrop');
							setShowQuestions(true);
							localStorage.setItem('isInsightsOpened', 'true');
						}}
					></Button>
				</ToolTipOnText>
			</div>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={showDrawer}
			>
				{showDrawer && (
					<InsightsDrawer
						showQuestions={showQuestions}
						viewStateChange={setShowDrawer}
						stateChanger={setShowQuestions}
						sidebarEl={sidebarEl}
					/>
				)}
			</Backdrop>
		</>
	);
};
export default InsightsButton;
