import useToast from '../Toast/hooks/useToast';
import ToolTipOnText from '../Tooltip/Tooltip';
import { ExpandMore } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Grow from '@mui/material/Grow';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import { lazy, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../AppContext/actions/actions';
import { useAppContext } from '../../AppContext/App.context';
import DiggLogoSM from '../../assets/Images/Sidebar/DiggLogo.svg';
import DiggLogo from '../../assets/Logo/DigGrowthLogo.svg';
import DiggLogoDark from '../../assets/Logo/DiggLogoDark.svg';
import { themeColor } from '../../constants/themeColors';
import { useClickOutside } from '../../hooks/clickOutside';
import useApiService from '../../services/api.service';
import CommonEndpoints from '../../services/commonAPI.Service';
import useLocalStorage from '../../utils/localStorage';
import './HeaderSidebarWrapper.scss';
import CommonHeader from '../CommonHeader/CommonHeader';
import headerService from '../../services/header.service';
import SidebarIcons from './SidebarIcons/SidebarIcons';
import { SidebarHeading } from './sidebar.enum';
import { getUpdatedSideBarList } from './updateSideBarList';
import { sideBarSectionData } from '../../containers/Settings/CustomNavigationBar/CustomNavigationData';

// Components Import
const AccountSettings = lazy(() => import('./AccountSettings'));
const DataQualitySideBar = lazy(
	() => import('./CustomSideBarList/DataQualitySidebar/DataQualitySidebar')
);
const CampaignSetupSideBar = lazy(
	() => import('./CustomSideBarList/CampaignSetup/CampaignSetup')
);
const InsightsSideBar = lazy(
	() => import('./CustomSideBarList/Insights/InsightsSideBar')
);
const SettingsSideBar = lazy(
	() => import('./CustomSideBarList/SettingsSideBar/SettingsSIdeBar')
);

const isClient = useLocalStorage.getItem('isClient');
// globals
const drawerWidth = 224;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: '55px',
	display: 'flex',
	alignItems: 'start',
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop: any) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

function HeaderSidebarWrapper(props: any) {
	const theme: any = useTheme();
	const toaster: any = useToast();
	const navigate = useNavigate();
	const APIService = useApiService();
	const [appData, dispatch]: any = useAppContext();
	const { showSidebar } = appData;
	const isSystemEmbeded = useLocalStorage.getItem('embeded') || false;
	const reportName: any =
		useLocalStorage.getItem('customReportName') ||
		SidebarHeading.CUSTOM_REPORTING;
	const loginData = useLocalStorage.getItem('userData');
	let sideBarSectionDataCopy = [...sideBarSectionData];
	const [isSidebarOpen, setisSidebarOpen] = useState(showSidebar); //true
	const [sidebarHoverState, setSidebarHoverState] = useState({
		isHovered: false,
		iconHover: false,
		isSidebarHovered: false,
		isCustomDashboard: false,
		isCustomReporting: false,
	});
	const { isHovered, iconHover, isSidebarHovered } = sidebarHoverState;
	//default icon styles for collapse icon
	const [iconStyles, setIconStyles] = useState({
		backgroundColor: '#F5F5F5',
		bgColorDark: '#121212',
		color: '#333',
		darkModeColor: '#fff',
	});
	let connectedPlatform = useLocalStorage.getItem('connectedCRM') || [];
	const [isCustomDashboard, setIsCustomDashboard]: any = useState(
		useLocalStorage.getItem('isCustomDashboardEnable') || false
	);
	const isOptionSelected = (path: any, type?: any) =>
		type !== 0 && window.location.pathname.includes(path);

	const sidebarRef: any = useRef();

	//styles for collapse icon on hover
	const hoverIconStyles = {
		backgroundColor: `${theme.palette.text.tertiary}`,
		bgColorDark: `${theme.palette.text.tertiary}`,
		color: 'white',
		darkModeColor: '#fff',
	};

	//styles for default collapse icon
	const iconStyle = {
		backgroundColor: '#F5F5F5',
		bgColorDark: '121212',
		color: '#333',
		darkModeColor: '#fff',
	};

	//common styles for chevron left and right icon
	const iconStyleCommon = {
		height: '20px',
		width: '20px',
		backgroundColor:
			theme.palette.mode === 'dark'
				? iconStyles.bgColorDark
				: iconStyles.backgroundColor,
		borderRadius: '50%',
		border: theme.palette.mode === 'dark' ? '2px solid rgb(47, 47, 47)' : '',
		color:
			theme.palette.mode === 'dark'
				? iconStyles.darkModeColor
				: iconStyles.color,
		backdropFilter: 'blur(5px)',
		transition: 'all 0.2s ease-in-out',
	};

	{
		/*--------------------------------- Functions ---------------------------------------------- */
	}

	const handleisSidebarOpen = () => {
		setIconStyles({
			...iconStyle,
		});
		setSidebarHoverState((prevState) => ({
			...prevState,
			isHovered: false,
			iconHover: false,
			isCustomDashboard: false,
			isCustomReporting: false,
		}));
		setisSidebarOpen(!isSidebarOpen);
	};

	//decide css for custom dashboard and reporting icon hover
	const decideHoverClass = (title: any) => {
		switch (title) {
			case SidebarHeading.CUSTOM_REPORTING:
				return sidebarHoverState.isCustomReporting && !isSidebarOpen
					? theme.palette.mode === 'dark'
						? 'Sidebar__item__svg__darkModehover'
						: 'Sidebar__item__svg__lightModeHover'
					: '';
			case SidebarHeading.CUSTOM_DASHBOARD:
				return sidebarHoverState.isCustomDashboard && !isSidebarOpen
					? theme.palette.mode === 'dark'
						? 'Sidebar__item__svg__darkModehover'
						: 'Sidebar__item__svg__lightModeHover'
					: '';
			default:
				return 'Sidebar__item__svg__lightModeHover';
		}
	};

	const handleMouseEnter = (title: any) => {
		switch (title) {
			case SidebarHeading.CUSTOM_REPORTING:
				setSidebarHoverState((prevState) => ({
					...prevState,
					isCustomReporting: true,
					isCustomDashboard: false,
				}));
				break;
			case SidebarHeading.CUSTOM_DASHBOARD:
				setSidebarHoverState((prevState) => ({
					...prevState,
					isCustomDashboard: true,
					isCustomReporting: false,
				}));
				break;
		}
	};

	const handleMouseLeave = (title: any) => {
		switch (title) {
			case SidebarHeading.CUSTOM_REPORTING:
				setSidebarHoverState((prevState) => ({
					...prevState,
					isCustomReporting: false,
				}));
				break;
			case SidebarHeading.CUSTOM_DASHBOARD:
				setSidebarHoverState((prevState) => ({
					...prevState,
					isCustomDashboard: false,
				}));
				break;
		}
	};

	const updateIconStyles = () => {
		setSidebarHoverState((prevState) => ({ ...prevState, iconHover: true }));
		setIconStyles({
			...hoverIconStyles,
		});
	};

	const resetIconStyles = () => {
		setSidebarHoverState((prevState) => ({
			...prevState,
			iconHover: false,
			isSidebarHovered: false,
		}));
		setIconStyles({
			...iconStyle,
		});
	};

	const handleDrawerLeave = () => {
		setSidebarHoverState((prevState) => ({
			...prevState,
			isSidebarHovered: false,
		}));
		setIconStyles({
			...iconStyle,
		});
	};

	const embeded = useLocalStorage.getItem('embeded') ? '54px' : '0px';
	const getConnectedCRM = () => {
		const reqData: any = {
			headers: {
				// access_token: userData.access_token,
			},
		};

		return APIService.get(CommonEndpoints.getConnectedCRM(reqData), true)
			.then(async (res: any) => {
				let tempArray: any = [];
				if (res?.data?.length > 0) {
					for (let index = 0; index < res.data.length; index++) {
						tempArray.push(res.data[index].platform_id);
					}
				}
				useLocalStorage.setItem('connectedCRM', tempArray);
			})
			.catch((err: any) => {
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
			});
	};

	const eventFromService = (eventData: any) => {
		switch (eventData.type) {
			case 'isCustomDashboard':
				setIsCustomDashboard(eventData.data);
				break;
			case 'isCustomNavigationBarEnable':
				if (eventData.data == false) {
					setCustomizedSideBarData(sideBarSectionDataCopy);
					useLocalStorage.removeItem('sideBarSectionData');
				}
				break;
			case 'dataFromCustomNavigationBarToHeader':
				if (eventData.data) {
					const localSideBarSection: any =
						localStorage.getItem('sideBarSectionData');
					const parsedData = JSON.parse(localSideBarSection);
					setCustomizedSideBarData(parsedData);
				}
				break;
			default:
				break;
		}
	};

	const hidePopOverEffect = () => {
		const data = {
			type: 'hidePopupEffect',
			data: true,
		};
		headerService.emit(data);
	};

	const getCRM = async () => {
		if (connectedPlatform?.length == 0 && loginData) {
			await getConnectedCRM();
		}
	};

	{
		/*------------------------------------- useEffect --------------------------------------------- */
	}

	useEffect(() => {
		getCRM();
	}, []);

	useEffect(() => {
		let headerSubscription = headerService.subscribe(eventFromService);
		return () => {
			headerSubscription.unsubscribe();
		};
	}, []);

	function SidebarItem(data: any) {
		return (
			<>
				{data.hidden !== true ? (
					<ListItem
						onMouseEnter={data.onMouseEnter}
						style={
							data.heading
								? {
										pointerEvents: 'none',
										fontWeight: 700,
										display: 'flex',
										alignItems: 'end',
								  }
								: {}
						}
						className={
							!data.isSubCategory || data.isListItem
								? 'Sidebar__item'
								: 'Sidebar__subItem'
						}
						onClick={
							!data.onClick
								? () => {
										if (data.navigatePath) {
											hidePopOverEffect();
											navigate(data.navigatePath);
										}
								  }
								: data.onClick
						}
						sx={{
							mr: isSidebarOpen ? 3 : 'auto',
							justifyContent: 'center',
						}}
						key={data.title}
						button
						disabled={data.disabled}
					>
						{/* {data.title} */}
						{data.showBorderSelector && isOptionSelected(data.path) ? (
							<div className='Sidebar__lineSelector' />
						) : (
							<></>
						)}
						{/* added class for applying css on icon hover */}
						<ListItemIcon
							className={`${
								isOptionSelected(data.path)
									? 'Sidebar__item__svg__selected sidebar_svg'
									: 'sidebar_svg'
							} ${decideHoverClass(data.title)}
              `}
							style={
								data.icon
									? {
											display: 'inline',
									  }
									: { display: 'none' }
							}
							onMouseEnter={() => {
								handleMouseEnter(data.title);
							}}
							onMouseLeave={() => {
								handleMouseLeave(data.title);
							}}
							sx={{
								mr: isSidebarOpen ? 3 : 'auto',
								justifyContent: 'center',
							}}
						>
							<span>{data.icon}</span>
						</ListItemIcon>
						{data?.path == '/editProfile' && data.title.length > 15 ? (
							<ToolTipOnText
								title={data.title}
								ellipsisOnOverflow={
									!(data?.path == '/editProfile' && data.title.length > 15)
								}
								avoidOverflow={false}
								style={{ overflow: 'inherit', width: '80%' }}
								disableInteraction={false}
							>
								<ListItemText
									sx={{
										opacity: isSidebarOpen
											? 1
											: data.heading || data.isListItem
											? 1
											: !data.isSubCategory
											? 0
											: 1,
									}}
									style={{
										color: `${
											isOptionSelected(data.path)
												? themeColor.primary.main
												: theme.palette.mode === 'dark'
												? 'white'
												: 'black'
										}`,
										fontWeight: `${
											!data.heading
												? data.isSubCategory || !data.onClick
													? 'normal'
													: '600'
												: 700
										}`,
									}}
									className={
										!data.isSubCategory || data.isListItem
											? 'Sidebar__item__text'
											: 'Sidebar__item__SubText'
									}
									primary={
										<div
											className={
												data?.path == '/editProfile'
													? 'Sidebar__item__textOverflow'
													: ''
											}
										>
											{data.title}
										</div>
									}
									primaryTypographyProps={{ component: 'div' }}
								/>
							</ToolTipOnText>
						) : (
							<ListItemText
								sx={{
									opacity: isSidebarOpen
										? 1
										: data.heading || data.isListItem
										? 1
										: !data.isSubCategory
										? 0
										: 1,
								}}
								style={{
									color: `${
										isOptionSelected(data.path)
											? themeColor.primary.main
											: theme.palette.mode === 'dark'
											? 'white'
											: 'black'
									}`,
									fontWeight: `${
										!data.heading
											? data.isSubCategory || !data.onClick
												? 'normal'
												: '600'
											: 700
									}`,
								}}
								className={
									!data.isSubCategory || data.isListItem
										? 'Sidebar__item__text'
										: 'Sidebar__item__SubText'
								}
								primary={
									<div
										className={
											data?.path == '/editProfile'
												? 'Sidebar__item__textOverflow'
												: ''
										}
									>
										{data.title}
									</div>
								}
								primaryTypographyProps={{ component: 'div' }}
							/>
						)}
						{data.stateChange !== undefined ? (
							data.stateChange ? (
								<ChevronRightIcon color='primary' />
							) : (
								<ExpandMore style={{ color: '#BFBFBF' }} />
							)
						) : null}
					</ListItem>
				) : null}
			</>
		);
	}

	useEffect(() => {
		const connectedCRM = useLocalStorage.getItem('userData');
		if (connectedCRM?.connected_crms?.includes('Salesforce')) {
			dispatch(actions.onConnectorChanged(connectedCRM.connected_crms));
			return;
		}
		if (connectedCRM?.connected_crms?.includes('Hubspot_Crm')) {
			dispatch(actions.onConnectorChanged(connectedCRM.connected_crms));
			return;
		}
		dispatch(actions.onConnectorChanged([]));
	}, []);

	useClickOutside(sidebarRef, () => {
		hidePopOverEffect();
	});

	const decideCSSForSidebarList = (classNameFor: string) => {
		switch (classNameFor) {
			case 'sidebar':
				if (isSidebarOpen) {
					if (theme.palette.mode === 'light') return `Sidebar__list`;
					else return `Sidebar__listDark`;
				}
				if (!isSidebarOpen) {
					if (theme.palette.mode === 'light') return `Sidebar__listClosed`;
					else return `Sidebar__listClosedDark`;
				}
				break;
			case 'sidebarSubCategory':
				if (isSidebarOpen) {
					if (theme.palette.mode === 'light') return `Sidebar__subCategoryList`;
					else return `Sidebar__subCategoryListDark`;
				}
				if (!isSidebarOpen) {
					if (theme.palette.mode === 'light')
						return `Sidebar__subCategoryListClosed`;
					else return `Sidebar__subCategoryListClosedDark`;
				}
				break;

			default:
				break;
		}
	};

	let className =
		location.pathname == '/insights/cmo/dashboard' ||
		location.pathname == '/custom/dashboard'
			? 'headerBorderRadius'
			: '';
	const sideBarLocal = useLocalStorage.getItem('sideBarSectionData');
	let tempSideBarLocal = sideBarLocal ? [...sideBarLocal] : [];
	const isCustomSideBar = useLocalStorage.getItem('isCustomizedSideBar') || 0;
	const [customizedSideBarData, setCustomizedSideBarData]: any = useState();

	useEffect(() => {
		setCustomizedSideBarData(
			isCustomSideBar == 0
				? sideBarSectionDataCopy
				: getUpdatedSideBarList(tempSideBarLocal, sideBarSectionDataCopy)
		);
	}, []);

	const getSideBarData = (uniqueKey: any) => {
		let tempCustomizedSideBar =
			isCustomSideBar == 0
				? sideBarSectionDataCopy
				: getUpdatedSideBarList([...tempSideBarLocal], sideBarSectionDataCopy);
		let foundedIndex = tempCustomizedSideBar.findIndex(
			(item: any) => item.unique_id == uniqueKey
		);
		return tempCustomizedSideBar[foundedIndex];
	};

	{
		/*------------------------------------ return --------------------------------------------*/
	}

	const Data = useLocalStorage.getItem('Client_data');
	const DSLogo: any = Data?.DSLogo;
	const LSLogo: any = Data?.LSLogo;
	const DOSLogo: any = Data?.DOSLogo;
	const LOSLogo: any = Data?.LOSLogo;

	const data = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
	};
	return (
		<>
			<div>
				<AppBar
					position='fixed'
					open={isSidebarOpen}
					style={{
						//handled responsivesness for height of header.
						height:
							location.pathname.includes('/dash') && window.innerWidth <= 1370
								? '5.5rem'
								: location.pathname.includes('/dash') &&
								  window.innerWidth >= 1526
								? '4.5rem'
								: '',
						borderRadius: '0px !important',
						placeContent: 'center',
						backgroundImage: 'none',
						backgroundColor:
							theme.palette.mode === 'light' ? 'white' : 'rgb(22, 27, 34)',
					}}
					className={
						isSidebarOpen
							? 'Sidebar__appbar ' + className
							: 'Sidebar__appbarClosed headerBorderRadius'
					}
				>
					{location.pathname == '/insights/cmo/dashboard' ||
					location.pathname == '/custom/dashboard' ? (
						<CommonHeader />
					) : null}
				</AppBar>
			</div>
			<div
				className='Sidebar'
				id='digg_sidebar'
				ref={sidebarRef}
				onMouseEnter={() => {
					setSidebarHoverState((prevState) => ({
						...prevState,
						isSidebarHovered: true,
					}));
				}}
				onMouseLeave={() => {
					setSidebarHoverState((prevState) => ({
						...prevState,
						iconHover: false,
						isSidebarHovered: false,
					}));
				}}
			>
				{/* used Drawer component for sidebar navigation */}
				<Drawer
					onMouseEnter={() => {
						setSidebarHoverState((prevState) => ({
							...prevState,
							isSidebarHovered: true,
						}));
					}}
					//function for resetting styles on mouse leave.
					onMouseLeave={handleDrawerLeave}
					sx={{
						flexShrink: 0,
						'& .MuiPaper-root': {
							backgroundColor: theme.palette.background.alt,
							borderRight: theme.palette.mode === 'light' ? 'none' : '',
						},
					}}
					className='Sidebar__drawer'
					variant='persistent'
					anchor='left'
					open={isSidebarOpen}
				>
					{/* used drawer header for rendering logo. */}
					<DrawerHeader>
						<div
							className={
								//digg logo
								isSidebarOpen
									? 'Sidebar__logoContainer'
									: 'Sidebar__logoContainer Sidebar__logoContainerClosed'
							}
						>
							{isClient ? (
								isSidebarOpen ? (
									<img
										src={theme.palette.mode === 'dark' ? DSLogo : LSLogo}
										alt='Logo'
										height={70}
									/>
								) : (
									<img
										src={theme.palette.mode === 'dark' ? DOSLogo : LOSLogo}
										alt='logo'
										style={
											embeded === '0px' ? { width: '50px' } : { width: '50px' }
										}
									/>
								)
							) : isSidebarOpen ? (
								<img
									src={theme.palette.mode === 'dark' ? DiggLogoDark : DiggLogo}
									alt='Company Logo'
									style={{ height: '55px', width: '90%' }}
								/>
							) : (
								<img
									src={DiggLogoSM}
									alt='digg logo'
									style={
										embeded === '0px'
											? { height: '65px', width: '65%' }
											: { height: '55px', width: '70%' }
									}
								/>
							)}
						</div>
					</DrawerHeader>
					<div
						className='Sidebar__divider'
						style={{ backgroundColor: theme.palette.background.mediumMain }}
					>
						<div
							style={{
								marginTop: isSidebarOpen ? '' : '-10px',
								// border: '2px solid red',
								// width: '100%',
							}}
						>
							<Tooltip
								open={
									(isSidebarOpen || !isSidebarOpen) &&
									iconHover &&
									isSidebarHovered
								}
								className='Sidebar__tooltip'
								style={{
									marginTop: isSidebarOpen ? '-5%' : '',
									marginLeft: isSidebarOpen ? '50%' : '30%',
									// overflow: 'visible',
									position: isSidebarOpen ? 'absolute' : 'absolute',
									zIndex: '999',
									...iconStyleCommon,
									...(isSidebarOpen && { right: '105px' }),
									...isSidebarOpen,
									...(isSidebarOpen || { marginTop: '' }),
								}}
								title={isSidebarOpen ? 'Collapse Side Bar' : 'Open Side Bar'}
								onMouseLeave={() => {
									setSidebarHoverState((prevState) => ({
										...prevState,
										iconHover: false,
									}));
									setIconStyles({
										...iconStyle,
									});
								}}
							>
								{/* used grow component from mui for transitional effects.*/}
								<Grow in={isSidebarHovered} timeout={1000}>
									{isSidebarOpen ? (
										<ChevronLeftIcon
											fontSize='small'
											className='Sidebar__collapsebutton'
											onClick={handleisSidebarOpen}
											onMouseEnter={updateIconStyles}
											onMouseLeave={resetIconStyles}
										/>
									) : (
										<ChevronRightIcon
											fontSize='small'
											className='Sidebar__collapsebutton'
											onClick={handleisSidebarOpen}
											onMouseEnter={updateIconStyles}
											onMouseLeave={resetIconStyles}
										/>
									)}
								</Grow>
							</Tooltip>
						</div>
					</div>
					{/**
					 * @author:Chetan.Dhiman-A
					 * @description: rendered tooltip and its styles on the basis of if side bar is open or closed.
					 */}

					{/* used slide component for applying sliding effect to divider. */}
					{/* <Slide
						direction='down'
						// in={isSidebarHovered}
						mountOnEnter
						unmountOnExit
					>
						<div
							className='Sidebar__divider'
							style={{
								backgroundColor: theme.palette.background.mediumMain,
							}}
						/>
					</Slide> */}

					<List
						className={
							isSidebarOpen ? 'Sidebar__list icon' : 'Sidebar__listClosed icon'
						}
					>
						<div
							className='Sidebar__divider'
							style={{ backgroundColor: theme.palette.background.mediumMain }}
						/>

						{!isSystemEmbeded ? (
							<AccountSettings isSidebarOpen={isSidebarOpen} />
						) : null}
					</List>
					<List className={decideCSSForSidebarList('sidebar')}>
						{/* Insights section */}
						{/* passed isSidebarOpen in props to all sections. */}
						{customizedSideBarData ? (
							<InsightsSideBar
								data={getSideBarData('insights')}
								isSidebarOpen={isSidebarOpen}
								isHovered={isHovered}
							/>
						) : null}
						<>
							{/* Data Quality Section */}
							{!isSystemEmbeded && customizedSideBarData ? (
								<DataQualitySideBar
									data={getSideBarData('data_quality')}
									isSidebarOpen={isSidebarOpen}
									isHovered={isHovered}
								/>
							) : null}
						</>
						{/* Custom Reporting section */}
						{!isSystemEmbeded ? (
							<>
								<SidebarItem
									title={reportName || SidebarHeading.CUSTOM_REPORTING}
									path='/custom/report'
									navigatePath='/custom/reporting'
									icon={SidebarIcons.getCustomReportingIcon(data)}
									onClick={() => {
										navigate('/custom/report/add');
									}}
									showBorderSelector
									hidden={isSystemEmbeded}
								/>
								<div
									hidden={isSystemEmbeded}
									className='Sidebar__divider'
									style={{
										backgroundColor: theme.palette.background.mediumMain,
									}}
								/>
							</>
						) : null}
						{/* Custom Dashboard section */}
						{isCustomDashboard && !isSystemEmbeded ? (
							<>
								<SidebarItem
									title={SidebarHeading.CUSTOM_DASHBOARD}
									path='/custom/dashboard'
									navigatePath='/custom/dashboard'
									icon={
										theme.palette.mode === 'dark'
											? SidebarIcons.getDarkModeCustomDashboardIcon(data)
											: SidebarIcons.getCustomDashboardIcon(data)
									}
									onClick={() => {
										navigate('/custom/dashboard');
									}}
									showBorderSelector
									hidden={isSystemEmbeded}
								/>
								<div
									hidden={isSystemEmbeded}
									className='Sidebar__divider'
									style={{
										backgroundColor: theme.palette.background.mediumMain,
									}}
								/>
							</>
						) : null}
						<div hidden={isSystemEmbeded} className='Sidebar__divider' />
						{/* Settings side bar section */}
						{customizedSideBarData && !isSystemEmbeded ? (
							<SettingsSideBar
								data={getSideBarData('settings')}
								isSidebarOpen={isSidebarOpen}
								isHovered={isHovered}
							/>
						) : null}
						<div
							hidden={isSystemEmbeded}
							className='Sidebar__divider'
							style={{
								backgroundColor: theme.palette.background.mediumMain,
							}}
						/>{' '}
					</List>
				</Drawer>
			</div>
		</>
	);
}

export default HeaderSidebarWrapper;
