import { useTheme } from '@emotion/react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import '../Header/Header.scss';

interface IPropsLoader {
	breadCrumbsList: any;
}

const BreadCrumbs: React.FC<IPropsLoader> = (props: any) => {
	const theme: any = useTheme();
	const [breadcrumbs, setBreadCrumbs] = useState([]);
	useEffect(() => {
		const tempList: any = [];
		props.breadCrumbsList &&
			props.breadCrumbsList.map((val: any, index: any) => {
				if (props.breadCrumbsList.length - 1 === index) {
					tempList.push(
						<Typography key={index} color={theme.palette.text.tertiary}>
							{val.name}
						</Typography>
					);
					return;
				}
				tempList.push(
					<Link
						underline={val.disabled ? 'none' : 'hover'}
						key={index}
						color={theme.palette.text.tertiary}
						href={val.link || null}
					>
						{val.name}
					</Link>
				);
			});
		setBreadCrumbs(tempList);
	}, []);
	return (
		<div className='BreadCrumbs__typography'>
			<Breadcrumbs separator='>' aria-label='breadcrumb'>
				{breadcrumbs}
			</Breadcrumbs>
		</div>
	);
};

export default BreadCrumbs;
