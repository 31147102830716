import DataQualityIcon from '../../../components/Sidebar/SidebarIcons/DataQualityIcon';
import SidebarIcons from '../../../components/Sidebar/SidebarIcons/SidebarIcons';

const insightsData = [
	{
		name: 'CMO',
		isChecked: true,
		isParent: true,
		unique_id: 'CMO',
		path: '/cmo/',
		children: [
			{
				name: 'CMO Dashboard',
				isChecked: true,
				unique_id: 'cmo_dashboard',
				isDefaultReport: true,
				path: '/insights/cmo/dashboard',
			},
			{
				name: 'Performance Report',
				isChecked: true,
				unique_id: 'performance_report',
				isDefaultReport: false,
				path: '/insights/cmo/performance-report',
			},
			{
				name: 'Channel Performance',
				isChecked: true,
				isDefaultReport: false,
				unique_id: 'channel_performance',
				path: '/insights/cmo/channel-performance',
			},
			{
				name: 'Sales Cycle',
				isChecked: true,
				isDefaultReport: false,
				unique_id: 'sales_cycle',
				path: '/insights/cmo/sales-cycle',
			},
			{
				name: 'Break-Even Cohort',
				isChecked: true,
				unique_id: 'break_even_cohort',
				isDefaultReport: false,
				path: '/insights/cmo/cohort',
			},
		],
	},
	{
		name: 'Marketing Ops',
		isChecked: true,
		isParent: true,
		path: '/mo/',
		unique_id: 'marketing_ops',
		children: [
			{
				name: 'Path Length',
				isChecked: true,
				unique_id: 'path_length',
				isDefaultReport: false,
				path: '/insights/mo/path-length',
			},
			{
				name: 'Model Comparison',
				isChecked: true,
				unique_id: 'model_comparison',
				isDefaultReport: false,
				path: '/insights/mo/model-comparison',
			},
			{
				name: 'Metrics Hub',
				isChecked: true,
				unique_id: 'metrics_hub_report',
				path: '/insights/mo/metrics/hub',
			},
			{
				name: 'Podcast Insights',
				isChecked: true,
				unique_id: 'podcast_insights',
				path: '/podcast-insights',
			},
		],
	},
	{
		name: 'Channel',
		isChecked: true,
		unique_id: 'channel',
		path: '/insights/channel/',
		isParent: true,
		children: [
			{
				name: 'Paid Campaign Tracker',
				isChecked: true,
				unique_id: 'paid_campaign_tracker',
				isDefaultReport: false,
				path: '/insights/channel/paid-campaign-tracker',
			},
			{
				name: 'Paid Media Tracker',
				unique_id: 'paid_media_tracker',
				isChecked: true,
				isDefaultReport: false,
				path: '/insights/channel/paid-media-tracker',
			},
			{
				name: 'User Path Analysis',
				isDefaultReport: false,
				unique_id: 'user_path_analysis',
				isChecked: true,
				path: '/insights/channel/path-analysis',
			},
			{
				name: 'Top Conversion Paths',
				isDefaultReport: false,
				isChecked: true,
				unique_id: 'top_conversion_path',
				path: '/insights/channel/top-conversion',
			},
			{
				name: 'Email  Marketing Dashboard',
				isDefaultReport: false,
				unique_id: 'email_marketing_dashboard',
				isChecked: true,
				path: '/insights/channel/hubspot-report',
			},
			{
				name: 'SEO Dashboard',
				isDefaultReport: false,
				unique_id: 'seo_dashboard',
				isChecked: true,
				path: '/insights/channel/search-console-report',
			},
			{
				name: 'Social Media',
				isDefaultReport: false,
				unique_id: 'social_media',
				isChecked: true,
				path: '/insights/channel/smd',
			},
		],
	},
	{
		name: 'ABM',
		isChecked: true,
		isParent: true,
		unique_id: 'abm',
		path: '/insights/abm',
		isDependentFeature: true,
		children: [
			{
				name: 'Overview Dashboard',
				isChecked: true,
				unique_id: 'overview_dashboard',
				isDependentFeature: true,
				isDefaultReport: false,
				path: '/insights/abm/comparison-dashboard',
			},
			{
				name: 'Accounts',
				isChecked: true,
				unique_id: 'accounts',
				isDependentFeature: true,
				isDefaultReport: false,
				path: '/insights/abm/accounts',
			},
			{
				name: 'Configuration',
				isDependentFeature: true,
				unique_id: 'configuration',
				isDefaultReport: false,
				isChecked: true,
				path: '/insights/abm/configuration',
			},
		],
	},
	// {
	// 	name: 'AL/ML',
	// 	isChecked: true,
	// 	isParent: true,
	// 	path: '/insights/advertisement-channel-effect',
	// 	children: [
	// 		{
	// 			name: 'ML Insights',
	// 			isChecked: true,
	// 			path: '/insights/advertisement-channel-effect',
	// 		},
	// 	],
	// },
];

const dataQualitySections = [
	{
		name: 'Data Quality',
		isChecked: true,
		isParent: true,
		unique_id: 'data_quality',
		path: '/cmo/',
		children: [
			{
				name: 'SF Scoring',
				isChecked: true,
				unique_id: 'sf_scoring',
				isDefaultReport: false,
				path: '/data-quality/sf-scoring',
			},
			{
				name: 'HubSpot Scoring',
				isChecked: true,
				unique_id: 'hubspot_scoring',
				isDefaultReport: false,
				path: '/data-quality/hubspot-scoring',
			},
		],
	},
	{
		name: 'Campaign Setup',
		path: '/governance/',
		isChecked: true,
		isParent: true,
		isDependentFeature: true,
		unique_id: 'campaign_setup',
		icon: SidebarIcons.getCampaignIcon(),
		children: [
			{
				name: 'Create Campaign',
				unique_id: 'create_campaign',
				isChecked: true,
				path: '/governance/campaigns/list',
				isDependentFeature: true,
				isDefaultReport: false,
			},
			{
				name: 'Select Platform',
				isChecked: true,
				isDependentFeature: true,
				unique_id: 'select_platform',
				path: '/governance/parameter',
				isDefaultReport: false,
			},
			{
				name: 'Channel Setup',
				isChecked: true,
				isDependentFeature: true,
				unique_id: 'channel_setup',
				path: '/governance/channels/list',
				isDefaultReport: false,
			},
			{
				name: 'Attributes Setup',
				isChecked: true,
				isDependentFeature: true,
				unique_id: 'atrribute_setup',
				path: '/governance/attributes/list',
				isDefaultReport: false,
			},
		],
	},
];

const settingsSection = [
	{
		name: 'Global Settings',
		isChecked: true,
		isDisabled: true,
		unique_id: 'global_settings',
		path: '/settings/global',
		isDefaultReport: false,
	},
	{
		name: 'Integrations',
		unique_id: 'integrations',
		isChecked: true,
		isDefaultReport: false,
		path: '/settings/connectors',
	},
	{
		name: 'Campaign Mapping',
		unique_id: 'campaign_mapping',
		isChecked: true,
		isDefaultReport: false,
		path: '/settings/campaign-mapping',
	},
	{
		name: 'Channel Manager',
		isChecked: true,
		unique_id: 'channel_manager',
		isDefaultReport: false,

		path: '/settings/channel-manager',
	},
	{
		name: 'Segment Manager',
		isChecked: true,
		isDefaultReport: false,
		unique_id: 'segment_manager',
		path: '/settings/segment-manager',
	},
	{
		name: 'Content Grouping',
		isChecked: true,
		isDefaultReport: false,
		unique_id: 'content_grouping',
		path: '/settings/content-grouping',
	},
	{
		name: 'User Access Management',
		isChecked: true,
		unique_id: 'user_access_management',
		isDefaultReport: false,
		path: '/settings/user-access-management/list',
	},
];

export const sideBarSectionData = [
	{
		name: 'Insights',
		value: 0,
		isChecked: true,
		isGrandParent: true,
		isNoHeading: false,
		icon: SidebarIcons.getDashboardIcon(),
		children: insightsData,
		isParentReport: true,
		unique_id: 'insights',
		path: '/insights',
	},
	{
		name: 'Data Quality',
		value: 1,
		isChecked: true,
		isNoHeading: true,
		path: '/data-quality/',
		isParentReport: false,
		unique_id: 'data_quality',
		icon: DataQualityIcon.getSFScoringIcon(),
		isGrandParent: true,
		isDataQuality: true,
		children: dataQualitySections,
	},
	{
		name: 'Settings',
		value: 2,
		path: '/settings/',
		isChecked: true,
		isNoHeading: true,
		unique_id: 'settings',
		isParentReport: false,
		icon: SidebarIcons.getSettingsIcon(),
		isGrandParent: true,
		children: settingsSection,
	},
];
