import React from 'react';

const CalendarIcon = (props) => {
	return (
		<div>
			<svg
				width='25'
				height='25'
				viewBox='0 0 25 25'
				fill={props.fill || '#9149ff'}
				xmlns='http://www.w3.org/2000/svg'
				{...props}
			>
				<g clip-path='url(#clip0_541_319)'>
					<path
						d='M8.33333 12.5H6.25C5.10104 12.5 4.16667 13.4344 4.16667 14.5833V16.6667C4.16667 17.8156 5.10104 18.75 6.25 18.75H8.33333C9.48229 18.75 10.4167 17.8156 10.4167 16.6667V14.5833C10.4167 13.4344 9.48229 12.5 8.33333 12.5ZM6.25 16.6667V14.5833H8.33333V16.6667H6.25ZM19.7917 2.08333H18.75V1.04167C18.75 0.466667 18.2844 0 17.7083 0C17.1323 0 16.6667 0.466667 16.6667 1.04167V2.08333H8.33333V1.04167C8.33333 0.466667 7.86771 0 7.29167 0C6.71562 0 6.25 0.466667 6.25 1.04167V2.08333H5.20833C2.33646 2.08333 0 4.41979 0 7.29167V19.7917C0 22.6635 2.33646 25 5.20833 25H19.7917C22.6635 25 25 22.6635 25 19.7917V7.29167C25 4.41979 22.6635 2.08333 19.7917 2.08333ZM5.20833 4.16667H19.7917C21.5146 4.16667 22.9167 5.56875 22.9167 7.29167V8.33333H2.08333V7.29167C2.08333 5.56875 3.48542 4.16667 5.20833 4.16667ZM19.7917 22.9167H5.20833C3.48542 22.9167 2.08333 21.5146 2.08333 19.7917V10.4167H22.9167V19.7917C22.9167 21.5146 21.5146 22.9167 19.7917 22.9167Z'
						fill={props.fill || '#9149ff'}
					/>
				</g>
				<defs>
					<clipPath id='clip0_541_319'>
						<rect width='25' height='25' fill={props.fill || '#9149ff'} />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default CalendarIcon;
