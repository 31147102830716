const BookmarkEndpoints = {
	createBookmark(data: any) {
		return {
			url: 'bookmark/create_bookmark',
			data: data.request,
			headers: data.headers,
		};
	},

	getBookmarks(data: any) {
		return {
			url: 'bookmark/get_bookmark',
			data: data.request,
			headers: data.headers,
		};
	},
	deleteBookmark(data: any) {
		return {
			url: 'bookmark/delete_bookmark',
			data: data.request,
			headers: data.headers,
		};
	},
};

export default BookmarkEndpoints;
