import React from 'react';

const insights = (props) => {
	return (
		<div>
			<svg
				width='25'
				height='25'
				viewBox='0 0 25 25'
				fill={props.fill}
				xmlns='http://www.w3.org/2000/svg'
				{...props}
			>
				<g clip-path='url(#clip0_556_382)'>
					<path
						d='M23.438 4.14166H20.2234C20.0174 4.14171 19.816 4.20282 19.6448 4.31729C19.4735 4.43175 19.34 4.59442 19.2612 4.78473C19.1824 4.97504 19.1618 5.18445 19.2019 5.38649C19.2421 5.58852 19.3413 5.77411 19.4869 5.91979L20.6182 7.05104L16.8828 10.7854C16.6843 10.9751 16.4203 11.081 16.1458 11.081C15.8712 11.081 15.6073 10.9751 15.4088 10.7854L15.2317 10.6073C14.6365 10.0386 13.845 9.72127 13.0218 9.72127C12.1986 9.72127 11.4071 10.0386 10.8119 10.6073L5.49942 15.9198C5.30369 16.1161 5.19395 16.3821 5.19434 16.6593C5.19473 16.9365 5.30522 17.2022 5.50151 17.3979C5.6978 17.5936 5.9638 17.7034 6.241 17.703C6.5182 17.7026 6.7839 17.5921 6.97963 17.3958L12.2921 12.0833C12.4905 11.8934 12.7545 11.7874 13.0291 11.7874C13.3037 11.7874 13.5677 11.8934 13.7661 12.0833L13.9432 12.2615C14.5386 12.8297 15.33 13.1467 16.1531 13.1467C16.9761 13.1467 17.7675 12.8297 18.363 12.2615L22.0984 8.52604L23.2296 9.65729C23.3758 9.80057 23.5609 9.89761 23.7619 9.9363C23.9629 9.97498 24.1709 9.95359 24.3598 9.87481C24.5487 9.79602 24.7102 9.66332 24.8241 9.49329C24.9381 9.32326 24.9994 9.12343 25.0005 8.91875V5.70416C25.0005 5.28976 24.8358 4.89233 24.5428 4.59931C24.2498 4.30628 23.8524 4.14166 23.438 4.14166Z'
						fill={props.fill}
					/>
					<path
						d='M23.9583 22.8917H5.20833C4.37953 22.8917 3.58468 22.5624 2.99862 21.9764C2.41257 21.3903 2.08333 20.5955 2.08333 19.7667V1.04167C2.08333 0.7654 1.97359 0.500448 1.77824 0.305097C1.58289 0.109747 1.31793 0 1.04167 0C0.765399 0 0.500448 0.109747 0.305097 0.305097C0.109747 0.500448 0 0.7654 0 1.04167L0 19.7667C0.00165402 21.1475 0.550919 22.4713 1.52731 23.4477C2.50371 24.4241 3.82751 24.9733 5.20833 24.975H23.9583C24.2346 24.975 24.4996 24.8653 24.6949 24.6699C24.8903 24.4746 25 24.2096 25 23.9333C25 23.6571 24.8903 23.3921 24.6949 23.1968C24.4996 23.0014 24.2346 22.8917 23.9583 22.8917Z'
						fill={props.fill}
					/>
				</g>
				<defs>
					<clipPath id='clip0_556_382'>
						<rect width='25' height='25' fill={props.fill} />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default insights;
