import axios from 'axios';
import useToast from '../components/Toast/hooks/useToast';
import { LogoutSession } from './logutSessionFromKeyCloak';
/**
 * Creating empty instance of axios for network, as API urls are dynamic
 */

const useAxiosInterceptor = () => {
	const toaster: any = useToast();

	const cancelTokenSource = axios.CancelToken.source();

	const instance = axios.create({
		validateStatus: function (status: any) {
			return status >= 200;
		},
	});
	// Add a response interceptor
	instance.interceptors.request.use(function (response: any) {
		response.validateStatus = function (status: any) {
			return status >= 200;
		};
		return response;
	});
	instance.interceptors.response.use(
		async (response) => {
			if (
				response.status &&
				response.status !== 200 &&
				response.status !== 201 &&
				response.status !== 202
			) {
				if (response.status === 440) {
					LogoutSession.logoutSessionFromKeyCloak();
					cancelTokenSource.cancel(
						'Request was cancelled due to status code 440'
					);
					toaster.addToast({
						message: 'Session Expired',
						timeout: 2000,
						type: 'error',
					});
					window.location.href = '/login';
					localStorage.clear();
				}
				if (response.status == 500 || response.status == 404) {
					return Promise.reject(response.data);
				} else {
					return Promise.reject(response.data);
				}
			}
			return response.data;
		},
		(error: any) => {
			return Promise.reject(error);
		}
	);
	return instance;
};

export default useAxiosInterceptor;
