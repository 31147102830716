import React, { createContext, useContext, useEffect, useState } from 'react';
import { DateTimeFormatter } from './DateFormatter';

const DateRangeContext = createContext();

export const useDateRange = () => useContext(DateRangeContext);

export const DateRangeProvider = ({ children }) => {
	// Function to retrieve date range from local storage
	const getStoredDateRange = () => {
		const storedRange = localStorage.getItem('dateRanges');
		return storedRange ? JSON.parse(storedRange) : null;
	};

	// Initial state from local storage or default values
	const initialDateRange = getStoredDateRange() || {
		start_date: DateTimeFormatter.getPastValueFromToday(6),
		end_date: DateTimeFormatter.getCurrentDate(),
		pre_end_date: DateTimeFormatter.getPastValueFromToday(1),
		pre_start_date: DateTimeFormatter.getPastValueFromToday(2),
	};

	const [dateRange, setDateRange] = useState(initialDateRange);

	// Update local storage whenever date range changes
	useEffect(() => {
		localStorage.setItem('dateRanges', JSON.stringify(dateRange));
	}, [dateRange]);

	return (
		<DateRangeContext.Provider value={{ dateRange, setDateRange }}>
			{children}
		</DateRangeContext.Provider>
	);
};

export default DateRangeContext; // Export the context itself
