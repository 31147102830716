import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import TotalSpent from './containers/Reports/Dashboard/reports/TotalSpent';

// Function to resolve loading chunk issue

const lazyWithRetry = (componentImport: any, name: any) => async () => {
	const pageHasAlreadyBeenForceRefreshed = JSON.parse(
		window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
	);

	try {
		const component = await componentImport();

		window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');

		return component;
	} catch (error) {
		if (!pageHasAlreadyBeenForceRefreshed) {
			// Assuming that the user is not on the latest version of the application.
			// Let's refresh the page immediately.
			window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
			return window.location.reload();
		}

		// The page has already been reloaded
		// Assuming that user is already using the latest version of the application.
		// Let's let the application crash and raise the error.
		throw error;
	}
};

const GA4SingleuserReporting = lazy(
	lazyWithRetry(
		() => import('./containers/GA4Reporting/GA4Reporting'),
		'GA4SingleUserReporting'
	)
);

const GA4SingleuserReportingPDF = lazy(
	lazyWithRetry(
		() =>
			import('./containers/GA4ReportingDuplicate/GA4Reporting/GA4Reporting'),
		'GA4SingleUserReportingPDF'
	)
);

const PaidCampaignTrackerPDF = lazy(
	lazyWithRetry(
		() =>
			import(
				'./containers/Reports/PaidCampaignReportsDuplicate/PaidCampaignTracker'
			),
		'paidCampaignTrackerPDF'
	)
);

const YoutubeInsta = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/SocialMediaPlatforms/YoutubeInsta'),
		'youtubeInsta'
	)
);

const HSScoring = lazy(
	lazyWithRetry(
		() => import('./containers/DataQuality/hubspotscoring/HSScoring'),
		'hsScoring'
	)
);

const SFScoring = lazy(
	lazyWithRetry(
		() => import('./containers/DataQuality/SFScoring/SFScoring'),
		'sfScoring'
	)
);

const PaidCampaignTracker = lazy(
	lazyWithRetry(
		() =>
			import('./containers/Reports/PaidCampaignReports/PaidCampaignTracker'),
		'paidCampaignTracker'
	)
);

const ChannelPerformance = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/ChannelPerformance/ChannelPerformance'),
		'channelPerformance'
	)
);

const CampaignMapping = lazy(
	lazyWithRetry(
		() => import('./containers/Settings/CampaignMapping/CampaignMapping'),
		'campaignMapping'
	)
);

const MarketingDashboard1 = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/Dashboard/Insights1'),
		'marketingDashboard1'
	)
);

const PaidMediaTrackerNode = lazy(
	lazyWithRetry(
		() => import('./containers/Attribution/PMTnode/PaidMediaTrackerNode'),
		'paidMediaTrackerNode'
	)
);

const ChooseAccount = lazy(
	lazyWithRetry(
		() => import('./containers/ChooseAccount/ChooseAccount'),
		'chooseAccount'
	)
);

const CMODashboard = lazy(
	lazyWithRetry(
		() => import('./containers/CMODashboard/CmoDashboard'),
		'cmoDashboard'
	)
);

const CustomDashboard = lazy(
	lazyWithRetry(
		() => import('./containers/CustomDashbaord/CustomDashboard'),
		'customDashboard'
	)
);

const Login = lazy(
	lazyWithRetry(() => import('./containers/Login/Login'), 'login')
);

const Iframe = lazy(lazyWithRetry(() => import('./iframe'), 'iframe'));

const InvitedUser = lazy(
	lazyWithRetry(() => import('./containers/Login/InvitedUser'), 'invitedUser')
);

const EditProfile = lazy(
	lazyWithRetry(
		() => import('./containers/EditProfile/EditProfile'),
		'editProfile'
	)
);
// const ResetPassword = lazy(() =>
//     import("./containers/Login/Login")
// );
const PerformanceReport = lazy(
	lazyWithRetry(
		() =>
			import('./containers/Reports/analytic-report-trend/AnalyticReportTrend'),
		'performanceReport'
	)
);

const SelectPlatform = lazy(
	lazyWithRetry(
		() => import('./containers/Platform/selectPlatform'),
		'selectPlatform'
	)
);

const ChannelList = lazy(
	lazyWithRetry(
		() => import('./containers/Channels/ChannelList'),
		'channelList'
	)
);

const TotalSpent = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/Dashboard/reports/TotalSpent'),
		'totalSpent'
	)
);
const MarketingSpent = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/Dashboard/reports/MarketingSpent'),
		'marketingSpent'
	)
);

const ManageChannel = lazy(
	lazyWithRetry(
		() => import('./containers/Channels/ManageChannelAttribute'),
		'manageChannel'
	)
);

const ManageAttribute = lazy(
	lazyWithRetry(
		() => import('./containers/Attributes/ManageAttributes'),
		'manageAttribute'
	)
);

const CohortReport = lazy(
	lazyWithRetry(
		() => import('./containers/Attribution/CohortReport/CohortReport'),
		'cohortReport'
	)
);

const PathAnalysis = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/PathAnalysis/PathAnalysis'),
		'pathAnalysis'
	)
);
const TopConversion = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/TopConversions/TopConversion'),
		'topConversion'
	)
);
const CampaignList = lazy(
	lazyWithRetry(
		() => import('./containers/Campaign/CampaignList'),
		'campaignList'
	)
);
const TemplateList = lazy(
	lazyWithRetry(
		() => import('./containers/Campaign/TemplateList'),
		'templateList'
	)
);
const AddCampaign = lazy(
	lazyWithRetry(
		() => import('./containers/Campaign/AddCampaign'),
		'addCampaign'
	)
);
const ViewCampaign = lazy(
	lazyWithRetry(
		() => import('./containers/Campaign/ViewCampaign'),
		'viewCampaign'
	)
);
const ViewTemplate = lazy(
	lazyWithRetry(
		() => import('./containers/Campaign/ViewTemplate'),
		'viewTemplate'
	)
);

const ComparisonReport = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/ComparisonReportNew/ComparisonReport'),
		'comparisonReport'
	)
);
const HubspotReports = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/HubspotDashboard/HubspotReports'),
		'hubspotReports'
	)
);
const SEODashboard = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/SEODashboard/SEODashboard'),
		'seoDashboard'
	)
);

const SalesCycle = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/SalesCycle/SalesCycle'),
		'salesCycle'
	)
);
const CampaignPath = lazy(
	lazyWithRetry(
		() => import('./containers/CampaignPath/CampaignPath'),
		'campaignPath'
	)
);
const Connectors = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/Connectors'),
		'Connectors'
	)
);

const ConfigureLinkedIn = lazy(
	lazyWithRetry(
		() =>
			import('./containers/Connectors/AdvertisementPlatform/ConfigureLinkedin'),
		'ConfigureLinkedIn'
	)
);

// Analytics
const ConfigureGoogle = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/Analytics/ConfigureGoogle'),
		'ConfigureGoogle'
	)
);

const ConfigGA4 = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/WebAnalytics/ConfigGA4'),
		'ConfigGA4'
	)
);
const ConfigGA4Reporting = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/WebAnalytics/ConfigGA4Reporting'),
		'ConfigGA4Reporting'
	)
);
const CustomReportList = lazy(
	lazyWithRetry(
		() => import('./containers/CustomReporting/CustomReportList'),
		'customReportList'
	)
);

const CustomReportingGraph = lazy(
	lazyWithRetry(
		() => import('./containers/CustomReporting/CustomReportGraphs'),
		'customReportingGraph'
	)
);

const ConfigureFacebook = lazy(
	lazyWithRetry(
		() =>
			import('./containers/Connectors/AdvertisementPlatform/ConfigureFacebook'),
		'ConfigureFacebook'
	)
);

const ConfigureAdRoll = lazy(
	lazyWithRetry(
		() =>
			import('./containers/Connectors/AdvertisementPlatform/ConfigureAdRoll'),
		'ConfigureAdRoll'
	)
);

const ConfigSalesforce = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/CRM/ConfigSalesforce'),
		'ConfigSalesforce'
	)
);

const CRMConfigHubspot = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/CRM/ConfigHubspot'),
		'CRMConfigHubspot'
	)
);

const BingAds = lazy(
	lazyWithRetry(
		() =>
			import('./containers/Connectors/AdvertisementPlatform/ConfigureBingAds'),
		'BingAds'
	)
);

const ConfigGSC = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/SearchConsoles/ConfigGSC'),
		'configGSC'
	)
);
const MarketoConfig = lazy(
	lazyWithRetry(
		() =>
			import(
				'./containers/Connectors/MarketingAutomation/Marketo/MarketoConfig'
			),
		'marketoConfig'
	)
);
const ConfigHubspotMA = lazy(
	lazyWithRetry(
		() => import('./containers/Connectors/MarketingAutomation/ConfigHubspot'),
		'configHubspotMA'
	)
);

const ConfigurationReportConfig = lazy(
	lazyWithRetry(
		() => import('./containers/ConfigurationReports/ConfigurationReportConfig'),
		'ConfigurationReportConfig'
	)
);

const ChannelPerformanceDrillDown = lazy(
	lazyWithRetry(
		() =>
			import(
				'./containers/Reports/ChannelPerformance/drilldown/ChannelPerformanceDrilldown'
			),
		'ChannelPerformanceDrillDown'
	)
);

const GoogleAds = lazy(
	lazyWithRetry(
		() =>
			import(
				'./containers/Connectors/AdvertisementPlatform/ConfigureGoogleAds'
			),
		'GoogleAds'
	)
);

const UserAccessManagement = lazy(
	lazyWithRetry(
		() => import('./containers/UAMSettings/UserSettings'),
		'UserAccessManagement'
	)
);

const AddUserSettings = lazy(
	lazyWithRetry(
		() => import('./containers/UAMSettings/AddUserSettings'),
		'AddUserSettings'
	)
);

const ResetPassword = lazy(
	lazyWithRetry(
		() => import('./containers/Login/ResetPassword'),
		'ResetPassword'
	)
);

const ComingSoon = lazy(
	lazyWithRetry(
		() => import('./components/ComingSoon/ComingSoon'),
		'ComingSoon'
	)
);

const PaidMediaTracker = lazy(
	lazyWithRetry(
		() => import('./containers/Attribution/PPC/PaidMediaTracker'),
		'PaidMediaTracker'
	)
);

const ChannelManager = lazy(
	() => import('./containers/ChannelManager/ChannelManagerContainer')
);

const SegmentManager = lazy(
	lazyWithRetry(
		() => import('./containers/SegmentManager/SegmentManager'),
		'SegmentManager'
	)
);

const ContentGrouping = lazy(
	lazyWithRetry(
		() => import('./containers/ContentGrouping/ContentGrouping'),
		'ContentGrouping'
	)
);
const AddCustomReport = lazy(
	lazyWithRetry(
		() => import('./containers/CustomReporting/AddCustomReportConfiguration'),
		'addCustomReport'
	)
);

// Settings

const GlobalSettings = lazy(
	lazyWithRetry(
		() => import('./containers/Settings/GlobalSettings/GlobalSettings'),
		'GlobalSettings'
	)
);

const SocialMediaReports = lazy(
	lazyWithRetry(
		() => import('./containers/SocialMedia/SocialMediaReports'),
		'SocialMediaReports'
	)
);

const ABMConfig = lazy(
	lazyWithRetry(() => import('./containers/ABM/ABMConfiguration'), 'ABMConfig')
);

const ABMAccounts = lazy(
	lazyWithRetry(() => import('./containers/ABM/Accounts'), 'ABMAccounts')
);

const ABMAccountDashboard = lazy(
	lazyWithRetry(
		() => import('./containers/ABM/AccountDashboard'),
		'ABMAccountDashboard'
	)
);

const ABMComparisonDashboard = lazy(
	lazyWithRetry(
		() => import('./containers/ABM/ComparisonDashboard'),
		'ABMComparisonDashboard'
	)
);
const PodcastInsightsPage = lazy(
	lazyWithRetry(
		() => import('./containers/Reports/Podcast-Insights/PodcastInsights'),
		'PodcastInsightsPage'
	)
);
const AppRoutes: any = (props: any) => (
	<Suspense fallback=''>
		<Routes>
			<Route path='/account' element={<ChooseAccount />} />
			<Route path='/podcast-insights' element={<PodcastInsightsPage />} />

			<Route path='invited/login' element={<InvitedUser />} />
			<Route path='/login' element={<Login />} />
			<Route path='/editProfile' element={<EditProfile />} />
			<Route path='/reset-password' element={<ResetPassword />} />
			<Route path='' element={<Navigate to='/login' />} />
			{/* <Route path='/insights/cmo/dashboard' element={<CmoDashboard />} /> */}
			<Route path='/insights/cmo/dashboard' element={<CMODashboard />} />
			<Route path='/custom/dashboard' element={<CustomDashboard />} />
			<Route path='/ga4/reporting' element={<GA4SingleuserReporting />} />
			<Route
				path='/insights/mo/metrics/hub'
				element={<GA4SingleuserReporting />}
			/>
			<Route
				path='/insights/mo/metrics/hub/pdf'
				element={<GA4SingleuserReportingPDF />}
			/>
			<Route
				path='/insights/channel/paid-campaign-tracker/pdf'
				element={<PaidCampaignTrackerPDF />}
			/>
			<Route
				path='/insights/cmo/dashboard1'
				element={<MarketingDashboard1 />}
			/>
			<Route
				path='/configuration/report'
				element={<ConfigurationReportConfig />}
			></Route>
			<Route
				path='/custom/reporting/'
				element={<CustomReportingGraph />}
			></Route>
			<Route path='/custom/report' element={<CustomReportList />}></Route>
			<Route
				path='/custom/report/module'
				element={<CustomReportList />}
			></Route>
			<Route
				path='/custom/report/module'
				element={<CustomReportList />}
			></Route>
			<Route path='/custom/report/add' element={<AddCustomReport />}></Route>
			{/* <Route path='/custom/report/add' element={<AddCustomReport />}></Route> */}
			<Route
				path='/insights/cmo/performance-report'
				element={<PerformanceReport />}
			/>
			<Route
				path='/insights/cmo/channel-performance'
				element={<ChannelPerformance />}
			/>
			{/* Cohort here */}
			<Route path='/insights/cmo/cohort' element={<CohortReport />} />
			<Route path='/data-quality/sf-scoring' element={<SFScoring />} />
			<Route path='/data-quality/hubspot-scoring' element={<HSScoring />} />
			{/* Sales Cycle */}
			<Route path='/insights/cmo/sales-cycle' element={<SalesCycle />} />
			{/* Path Lenght here */}
			<Route path='/insights/mo/path-length' element={<CampaignPath />} />
			<Route
				path='/insights/mo/model-comparison'
				element={<ComparisonReport />}
			/>
			<Route
				path='/insights/channel/hubspot-report'
				element={<HubspotReports />}
			/>
			<Route
				path='/insights/channel/search-console-report'
				element={<SEODashboard />}
			/>

			{/* PPC here */}

			<Route
				path='/insights/channel/paid-campaign-tracker'
				element={<PaidCampaignTracker />}
			/>
			<Route
				path='/insights/channel/paid-media-tracker'
				element={<PaidMediaTracker />}
			/>
			<Route
				path='/insights/channel/node/paid-media-tracker'
				element={<PaidMediaTrackerNode />}
			/>
			<Route
				path='/insights/channel/path-analysis'
				element={<PathAnalysis />}
			/>
			<Route
				path='/insights/channel/top-conversion'
				element={<TopConversion />}
			/>

			{/* Campaign */}
			<Route path='/governance/parameter' element={<SelectPlatform />} />
			<Route path='/iframe-testing' element={<Iframe />} />
			<Route path='/governance/channels/list' element={<ChannelList />} />
			<Route path='/governance/channels/manage' element={<ManageChannel />} />
			<Route path='/governance/attributes/list' element={<ManageAttribute />} />
			<Route path='/governance/campaigns/list' element={<CampaignList />} />
			<Route
				path='/governance/campaigns/templates'
				element={<TemplateList />}
			/>
			<Route path='/governance/campaigns/add' element={<AddCampaign />} />
			<Route path='/governance/campaigns/view' element={<ViewCampaign />} />
			<Route
				path='/governance/campaigns/template/view'
				element={<ViewTemplate />}
			/>
			<Route path='/governance/campaigns/edit' element={<AddCampaign />} />
			<Route path='/governance/template/edit' element={<AddCampaign />} />
			<Route path='/governance/campaigns/clone' element={<AddCampaign />} />
			{/* Settings */}
			<Route path='/settings/global' element={<GlobalSettings />} />
			<Route path='/settings/connectors' element={<Connectors />} />
			<Route
				path='/settings/connectors/:type/:configure'
				element={<YoutubeInsta />}
			/>
			<Route path='/account/:id/:token' element={<ChooseAccount />} />
			<Route
				path='/settings/user-access-management'
				element={<AddUserSettings />}
			/>

			{/* <Route
				path='/settings/connectors/linkedin/configure'
				element={<ConfigureLinkedIn />}
			/> */}
			{/* <Route
				path='/settings/connectors/facebook/configure'
				element={<ConfigureFacebook />}
			/> */}
			<Route
				path='/settings/connectors/adroll/configure'
				element={<ConfigureAdRoll />}
			/>
			<Route
				path='/settings/connectors/crm/configure'
				element={<ConfigSalesforce />}
			/>
			<Route
				path='/settings/connectors/crm/hubspot/configure'
				element={<CRMConfigHubspot />}
			/>
			<Route
				path='/settings/connectors/googleconsole/configure'
				element={<ConfigGSC />}
			/>

			<Route
				path='/settings/connectors/bing-ads/configure'
				element={<BingAds />}
			/>
			{/* <Route path='/settings/apiKeys' element={<APIKeys />} /> */}
			<Route
				path='/settings/connectors/google-ads/configure'
				element={<GoogleAds />}
			/>
			<Route
				path='/settings/connectors/google/configure'
				element={<ConfigureGoogle />}
			/>
			<Route
				path='/settings/connectors/google/configure'
				element={<ConfigureGoogle />}
			/>
			<Route
				path='/settings/connectors/googleanalytics/configure'
				element={<ConfigGA4 />}
			/>
			<Route
				path='/settings/connectors/googleanalytics/configure/reporting'
				element={<ConfigGA4Reporting />}
			/>
			<Route
				path='/settings/connectors/ma/marketo/configure'
				element={<MarketoConfig />}
			/>
			<Route
				path='/settings/connectors/hubspot-ma/configure'
				element={<ConfigHubspotMA />}
			/>

			<Route
				path='/settings/user-access-management/list'
				element={<UserAccessManagement />}
			/>
			{/* <Route path='/settings/apiKeys' element={<APIKeys />} /> */}

			<Route path='/settings/channel-manager' element={<ChannelManager />} />
			<Route path='/settings/segment-manager' element={<SegmentManager />} />
			<Route path='/insights/total-spent' element={<TotalSpent />} />
			<Route path='/insights/marketing-spent' element={<MarketingSpent />} />
			<Route
				path='/insights/channel-performance-report'
				element={<ChannelPerformanceDrillDown />}
			/>
			<Route path='/settings/content-grouping' element={<ContentGrouping />} />
			<Route path='/insights/abm/configuration' element={<ABMConfig />} />
			<Route path='/insights/abm/accounts' element={<ABMAccounts />} />
			<Route
				path='/insights/abm/account-dashboard/:accountId'
				element={<ABMAccountDashboard />}
			/>
			<Route
				path='/insights/abm/comparison-dashboard'
				element={<ABMComparisonDashboard />}
			/>
			<Route path='/settings/campaign-mapping' element={<CampaignMapping />} />
			<Route path='/coming-soon' element={<ComingSoon />} />
			<Route path='/insights/channel/smd' element={<SocialMediaReports />} />
		</Routes>
	</Suspense>
);

export default AppRoutes;
