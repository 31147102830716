import React, { createContext, useContext, useEffect, useState } from 'react';
import { DateTimeFormatter } from './DateFormatter';

const DateTypeContext = createContext();

export const useDateType = () => useContext(DateTypeContext);

export const DateTypeProvider = ({ children }) => {
	const [dateType, setDateType] = useState(2);
	return (
		<DateTypeContext.Provider value={{ dateType, setDateType }}>
			{children}
		</DateTypeContext.Provider>
	);
};

export default DateTypeContext;
