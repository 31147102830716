import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import BookmarkIconComp from '../../assets/Images/bookmarkicon';
import {
	Collapse,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	useTheme,
} from '@mui/material';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { useAppContext } from '../../AppContext/App.context';
import DeleteIcon from '../../assets/Images/DeletePrimaryIcons';
import { useClickOutside } from '../../hooks/clickOutside';
import useLoader from '../../hooks/useLoader';
import useApiService from '../../services/api.service';
import useLocalStorage from '../../utils/localStorage';
import Button from '../Button/Button';
import AlertDialog from '../Dialog/Dialog';
import useToast from '../Toast/hooks/useToast';
import ToolTipOnText from '../Tooltip/Tooltip';
import AddBookmark from './AddBookmark';
import BookmarkEndpoints from './BookmarkEndpoints';
import './Bookmark.scss';

const BookMarkIcon = (props: any) => {
	const navigate = useNavigate();
	const theme: any = useTheme();
	const [bookmarks, setBookmarks] = useState([]);
	const [appData]: any = useAppContext();
	const { userData } = appData;
	const [dense, setDense] = useState(false);
	const [showList, setShowList] = useState(false);
	const deleteBookmarkRef: any = useRef();
	const [deleteId, setDeleteId] = useState('');
	const localStorage = useLocalStorage;
	const openDeleteBookmarkDialog = () => {
		deleteBookmarkRef.current.handleClickOpen();
		setShowList(false);
	};
	const onDiscardDeleteBookmark = () => {
		deleteBookmarkRef.current.handleClose();
	};
	const onDeleteBookmark = () => {
		const headers = {
			// access_token: userData.access_token,
		};
		const reqData = {
			request: {
				id: deleteId,
			},
			headers,
		};
		loader.showLoader();
		return APIService.post(BookmarkEndpoints.deleteBookmark(reqData), true)
			.then((res: any) => {
				if (res && res.status === 200) {
					let localStorageBookMarkList = localStorage.getItem('bookMarkList');
					let deletedBookMarkIndex = localStorageBookMarkList.findIndex(
						(element: any) => element.id == deleteId
					);
					localStorageBookMarkList.splice(deletedBookMarkIndex, 1);
					localStorage.setItem('bookMarkList', localStorageBookMarkList);
					toaster.addToast({
						message: 'Bookmark deleted successfully',
						timeout: 4000,
						type: 'success',
					});
					setDeleteId('');
					deleteBookmarkRef.current.handleClose();
					loader.hideLoader();
				}
			})
			.catch(async (err: any) => {
				loader.hideLoader();
				toaster.addToast({
					message: err.message || 'Something went wrong.',
					timeout: 8000,
					type: 'error',
				});
			});
	};
	const RenameBookmarkDialogRef: any = useRef();
	const openRenameBookmarkHandler = () => {
		RenameBookmarkDialogRef.current.handleClickOpen();
	};
	const onBookmarkNameUpdated = (data: any) => {};
	const bookmarkRef: any = useRef();
	const APIService = useApiService();
	useClickOutside(bookmarkRef, () => {
		setShowList(false);
	});
	const toaster: any = useToast();
	const loader: any = useLoader();
	const ref: any = useRef(null);
	const getBookmarksList = async () => {
		const headers = {
			// access_token: userData.access_token,
		};
		const reqData = {
			request: {},
			headers,
		};
		// loader.showLoader();
		return await APIService.get(BookmarkEndpoints.getBookmarks(reqData), true)
			.then((res: any) => {
				// loader.hideLoader();
				localStorage.setItem('bookMarkList', res.data);
				setBookmarks(res.data);
			})
			.catch((err: any) => {
				// loader.hideLoader();
				localStorage.setItem('bookMarkList', []);
				setBookmarks([]);
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
			});
	};

	useEffect(() => {
		getBookmarksList();
	}, []);

	const toggleBookmarkList = () => {
		const localBookMarkStorage: any = localStorage.getItem('bookMarkList');
		if (localBookMarkStorage && !localBookMarkStorage.length) {
			openRenameBookmarkHandler();
		} else {
			setShowList(!showList);
			if (!showList) {
				setBookmarks([]);
				setBookmarks(localBookMarkStorage ? localBookMarkStorage : []);
			}
		}
	};
	const setBookmarkStorage = (data: any) => {
		const storageData: any = localStorage.getItem('bookmarks');
		const parsedJSON: any = JSON.parse(JSON.stringify(storageData));
		if (parsedJSON == null) {
			localStorage.setItem('bookmarks', [data]);
		} else if (
			parsedJSON &&
			parsedJSON.findIndex((bookmark: any) => bookmark.id === data.id) == -1
		) {
			const bookmarks = [...storageData, data];
			localStorage.setItem('bookmarks', bookmarks);
		}
		setShowList(false);
	};
	const data1 = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
	};
	return (
		<>
			<div
				ref={bookmarkRef}
				style={{
					display: 'inherit',
				}}
			>
				<ToolTipOnText title='Bookmark'>
					<Button
						className='pinIcon bookmarkButtons'
						type='button'
						btnText=''
						variant='outlined'
						style={{
							minWidth: '0px',
							//  padding: '4px',
							// marginLeft: '10px',
						}}
						onClick={() => toggleBookmarkList()}
						startIcon={<BookmarkIconComp {...data1} />}
					/>
				</ToolTipOnText>

				{showList && (
					<Grid
						className='bookmark-list'
						bgcolor={theme.palette.background.light}
					>
						<List
							style={{ overflowY: 'auto' }}
							dense={dense}
							className={
								showList ? 'bookmark-content-display' : 'bookmark-content'
							}
						>
							<ListItem className='bookmark-button listItem'>
								<ListItemText
									className='addBookmark'
									primary='Bookmarks'
									color={theme.palette.text.primary}
									style={{
										fontWeight: 'bold',
										cursor: 'pointer',
										color: theme.palette.text.primary,
									}}
								/>
								<ToolTipOnText title='Add Bookmark'>
									<ListItemIcon
										sx={{
											minWidth: '20px',
											justifyContent: 'end',
											cursor: 'pointer',
											color: 'rgb(145, 73, 255)',
										}}
									>
										<span style={{ color: theme.palette.text.primary }}>
											<AddCircleOutlineOutlinedIcon
												color='primary'
												onClick={() => openRenameBookmarkHandler()}
											/>
										</span>
									</ListItemIcon>
								</ToolTipOnText>
							</ListItem>
							{bookmarks.map((data: any) => (
								<ListItem key={data.id} className='listItem'>
									<ToolTipOnText
										title={data.bookmark_name}
										ellipsisOnOverflow={true}
										avoidOverflow={false}
										style={{ overflow: 'inherit', width: '80%' }}
										disableInteraction={false}
										fromBookmark={true}
									>
										<ListItemText
											className='bookmarkListText'
											style={{ cursor: 'default' }}
											color={theme.palette.text.primary}
											primary={
												<Link
													onClick={() => setBookmarkStorage(data)}
													rel='noopener noreferrer'
													to={`${data.page_url}?bookmarkId=${data.id}`}
													target='_blank'
													style={{ textDecoration: 'none', color: 'grey' }}
													ref={ref}
												>
													<div
														className='bookmarkListText'
														style={{ color: theme.palette.text.primary }}
													>
														{data.bookmark_name}
													</div>
												</Link>
											}
										/>
									</ToolTipOnText>
									<ListItemIcon
										sx={{
											minWidth: '20px',
											justifyContent: 'end',
										}}
									>
										<span>
											<IconButton
												edge='end'
												className='bookmarkButtons'
												aria-label='add'
												style={{
													color: theme.palette.text.tertiary,
													padding: 0,
												}}
												onClick={() => {
													openDeleteBookmarkDialog();
													setDeleteId(data.id);
												}}
											>
												{/* <img src={DeleteIcon} style={{ width: '12px' }} /> */}
												<DeleteIcon {...data1} />
											</IconButton>
										</span>
									</ListItemIcon>
								</ListItem>
							))}
						</List>
					</Grid>
				)}
			</div>
			<Suspense fallback={<h3>Loading&hellip;</h3>}>
				<AlertDialog
					ref={deleteBookmarkRef}
					title='Delete Bookmark'
					confirm='Delete'
					discard='Cancel'
					onConfirm={onDeleteBookmark}
					hideCloseButton
					color='error'
					onDiscard={onDiscardDeleteBookmark}
					description='Are you sure you want to delete this Bookmark?'
				/>
			</Suspense>
			<Suspense fallback={<h3>Loading&hellip;</h3>}>
				<AddBookmark
					ref={RenameBookmarkDialogRef}
					payload={props.payload}
					onBookmarkNameUpdated={onBookmarkNameUpdated}
					bookmarkName={props.bookmarkName}
					viewType={props.viewType}
					getBookmarksList={() => {
						getBookmarksList();
					}}
				/>
			</Suspense>
		</>
	);
};
export default BookMarkIcon;
