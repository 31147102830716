//  defining suffix based on range
let ranges = [
	{ divider: 1e18, suffix: 'E' },
	{ divider: 1e15, suffix: 'P' },
	{ divider: 1e12, suffix: 'T' },
	{ divider: 1e9, suffix: 'G' },
	{ divider: 1e6, suffix: 'M' },
	{ divider: 1e3, suffix: 'K' },
	{ divider: 1, suffix: '' },
	{ divider: 0, suffix: '' },
];

function formatNumber(n: any): any {
	//  first fix to two decimal places
	if (!n) return n;
	if (n === 'NA') return n;
	if (n < 0) {
		return '-' + formatNumber(-n);
	}
	for (let i = 0; i < ranges.length; i++) {
		if (n < 1) return twoDecimal(n / ranges[6].divider);
		if (n >= ranges[i].divider) {
			let dec = n / ranges[i].divider;
			return twoDecimal(dec) + ranges[i].suffix;
		}
	}

	return n.toString();
}

function twoDecimal(n: any): any {
	if (n) {
		let round = Math.round((n + Number.EPSILON) * 100) / 100;
		return round;
	} else return 0;
}

export default formatNumber;
