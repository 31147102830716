import React from 'react';

const bookmarkicon = (props) => {
	return (
		<div>
			<svg
				width='25'
				height='25'
				viewBox='0 0 25 25'
				fill={props.fill || '#9149ff'}
				xmlns='http://www.w3.org/2000/svg'
				{...props}
			>
				<g clip-path='url(#clip0_541_286)'>
					<path
						d='M20.9765 25C20.5911 24.9989 20.2098 24.9215 19.8546 24.7722C19.4993 24.6229 19.1771 24.4047 18.9067 24.1302L12.5005 17.7615L6.0942 24.1344C5.68278 24.5518 5.15513 24.8356 4.58006 24.9487C4.00498 25.0619 3.40915 24.9992 2.87025 24.7688C2.32599 24.5498 1.8604 24.1719 1.53426 23.6843C1.20813 23.1967 1.03662 22.622 1.04212 22.0354V5.20833C1.04212 3.827 1.59085 2.50224 2.56761 1.52549C3.54436 0.548734 4.86912 0 6.25045 0L18.7505 0C19.4344 0 20.1117 0.134718 20.7436 0.396461C21.3755 0.658204 21.9497 1.04185 22.4333 1.52549C22.9169 2.00912 23.3006 2.58329 23.5623 3.21519C23.8241 3.8471 23.9588 4.52437 23.9588 5.20833V22.0354C23.9646 22.6215 23.7937 23.1958 23.4684 23.6834C23.143 24.171 22.6783 24.5492 22.1348 24.7688C21.768 24.9222 21.3742 25.0008 20.9765 25ZM6.25045 2.08333C5.42165 2.08333 4.6268 2.41257 4.04075 2.99863C3.45469 3.58468 3.12545 4.37953 3.12545 5.20833V22.0354C3.12508 22.209 3.17615 22.3788 3.27223 22.5234C3.3683 22.6679 3.50506 22.7808 3.66524 22.8476C3.82541 22.9145 4.00182 22.9324 4.17216 22.8991C4.34251 22.8658 4.49916 22.7827 4.62233 22.6604L11.7713 15.5552C11.9665 15.3612 12.2305 15.2523 12.5057 15.2523C12.7809 15.2523 13.0449 15.3612 13.24 15.5552L20.3807 22.6583C20.5038 22.7806 20.6605 22.8637 20.8308 22.897C21.0012 22.9304 21.1776 22.9124 21.3378 22.8456C21.4979 22.7787 21.6347 22.6658 21.7308 22.5213C21.8268 22.3767 21.8779 22.2069 21.8775 22.0333V5.20833C21.8775 4.37953 21.5483 3.58468 20.9622 2.99863C20.3762 2.41257 19.5813 2.08333 18.7525 2.08333H6.25045Z'
						fill={props.fill || '#9149ff'}
					/>
				</g>
				<defs>
					<clipPath id='clip0_541_286'>
						<rect width='25' height='25' fill={props.fill || '#9149ff'} />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default bookmarkicon;
