import Fade from '@mui/material/Fade';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme: any) => ({
	customTooltip: {
		color: 'black',
		backgroundColor: 'white',
		fontSize: '12px',
		textAlign: 'center',
		top: '-6',
		padding: '0.5rem 1rem',
		fontWeight: 600,
		zIndex: 'inherit',
		boxShadow: '6px 8px 18px #0000001A !important',
		borderRadius: '20px',
	},
	customArrow: {
		color: 'white',
	},
	NoArrow: {
		display: 'none',
	},
}));

function ToolTipOnText(props: any) {
	const [textOverflowed, setTextOverflowed]: any = useState(false);
	const textOverflow: any = useRef();
	const [title, setTitle]: any = useState('');
	const [fromBookmark] = useState(props.fromBookmark);

	useEffect(() => {
		shouldTextOverflow();
		window.addEventListener('resize', shouldTextOverflow);
	}, []);
	useEffect(
		() => () => {
			window.removeEventListener('resize', shouldTextOverflow);
		},
		[]
	);
	useEffect(() => {
		setTitle(props.title);
	}, [props.title]);

	const shouldTextOverflow = () => {
		if (props.fromBookmark) {
			const element = textOverflow?.current?.getElementsByTagName('a');
			const element2 = element[0]?.getElementsByTagName('div');
			setTextOverflowed(element2[0]?.scrollWidth > element2[0]?.clientWidth);
		} else {
			setTextOverflowed(
				textOverflow.current?.scrollWidth > textOverflow.current?.clientWidth
			);
		}
	};
	// @ts-ignore
	const classes = useStyles();
	return (
		<Tooltip
			title={title}
			classes={{
				tooltip: classes.customTooltip,
				arrow: props?.NoArrow ? classes.NoArrow : classes.customArrow,
			}}
			TransitionComponent={Fade}
			slotProps={{
				popper: {
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: props?.offset,
							},
						},
					],
				},
			}}
			arrow={props.showArrow ? props.showArrow : true}
			disableInteractive={
				props.disableInteraction !== null ||
				props.disableInteraction !== undefined
					? props.disableInteraction
					: true
			}
			disableHoverListener={props.ellipsisOnOverflow ? !textOverflowed : false}
			style={props.style ? props.style : {}}
			// open={true}
		>
			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					display: 'flex',
					alignContent: 'center',
					flexWrap: 'wrap',
					...props.style,
				}}
				ref={props.avoidOverflow ? null : textOverflow}
			>
				{props.children}
			</div>
		</Tooltip>
	);
}

export default ToolTipOnText;
