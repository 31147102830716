import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../AppContext/App.context';
import useLoader from '../../hooks/useLoader';
import useApiService from '../../services/api.service';
import Button from '../Button/Button';
import Input from '../Input/Input';
import useToast from '../Toast/hooks/useToast';
import '../../containers/ChannelManager/ChannelManager.scss';
import BookmarkEndpoints from './BookmarkEndpoints';
import useLocalStorage from '../../utils/localStorage';

interface Bookmark {
	bookmark_name: string;
}
const defaultBookmark: Bookmark = {
	bookmark_name: '',
};
const AddBookmark = forwardRef((props: any, ref: any) => {
	const APIService = useApiService();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [bookmarkName, setBookmarkName] = useState(props.bookmarkName);
	const toaster: any = useToast();
	const localStorage = useLocalStorage;
	const loader: any = useLoader();
	const [appData]: any = useAppContext();
	const { userData } = appData;
	const location = useLocation();
	useImperativeHandle(ref, () => ({
		handleClickOpen: () => {
			setBookmarkName(props.bookmarkName);
			setDialogOpen(true);
		},
		handleClose: () => {
			setDialogOpen(false);
			setBookmarkName(props.bookmarkName);
		},
	}));
	const AddBookmarkName = () => {
		if (bookmarkName.trim() === '') {
			toaster.addToast({
				message: 'Bookmark name required.',
				timeout: 2000,
				type: 'error',
			});
			return;
		}
		props.onBookmarkNameUpdated(bookmarkName);

		const headers = {
			// access_token: userData.access_token,
		};
		let bookmarkPayload: any = props.payload;
		if (bookmarkName.includes('Perfomance Report')) {
			bookmarkPayload[0].requestBody.showTabularData = props.viewType;
		}
		const requestData = {
			bookmark_name: bookmarkName,
			request_data: bookmarkPayload,
			page_url: location.pathname,
		};
		const reqData = {
			request: requestData,
			headers,
		};
		loader.showLoader();
		return APIService.post(BookmarkEndpoints.createBookmark(reqData), true)
			.then((res: any) => {
				if (res && res.status === 200) {
					let previousBookMarks: any;
					if (localStorage.getItem('bookMarkList')) {
						previousBookMarks = localStorage.getItem('bookMarkList');
					}
					let tempObj = previousBookMarks ? [...previousBookMarks] : [];
					tempObj.push(requestData);
					localStorage.setItem('bookMarkList', tempObj);
					toaster.addToast({
						message: 'Bookmark added successfully',
						timeout: 4000,
						type: 'success',
					});
					setBookmarkName(props.bookmarkName);
					setDialogOpen(false);
					props.getBookmarksList();
					loader.hideLoader();
				}
			})
			.catch(async (err: any) => {
				loader.hideLoader();
				toaster.addToast({
					message: err.message || 'Something went wrong.',
					timeout: 8000,
					type: 'error',
				});
			})
			.finally(() => {});
	};
	const onBookmarkNameChange = (event: any) => {
		setBookmarkName(event.target.value);
	};

	return (
		<Dialog
			open={dialogOpen}
			onClose={() => setDialogOpen(false)}
			aria-labelledby='add_bookmark'
			classes={{
				paper: 'ChannelManager__addChannel__editDialog',
			}}
		>
			<DialogTitle
				id='addBookmark__title'
				classes={{
					root: 'Dialog__titleRoot',
				}}
				style={{ paddingBottom: '0rem' }}
			>
				Add Bookmark
			</DialogTitle>
			<DialogContent id='addChannel__content' style={{ paddingBottom: '0rem' }}>
				<>
					<div style={{ padding: '0rem 0rem 0.5rem' }}>Bookmark Name</div>
					<div className='ChannelManager__addChannel__channelItem'>
						<div className='ChannelManager__addChannel__channelItem__input'>
							<Input
								name='bookmark_name'
								placeholder='Enter Bookmark Name'
								onChange={onBookmarkNameChange}
								defaultValue={bookmarkName}
							/>
						</div>
					</div>
					<div style={{ padding: '0.5rem 0.5rem 0.5rem 0.5rem' }}>URL</div>
					<div className='ChannelManager__addChannel__channelItem'>
						<div className='ChannelManager__addChannel__channelItem__input'>
							<Input
								name='url'
								defaultValue={window.location.origin + location.pathname}
								disabled
							/>
						</div>
					</div>
				</>
			</DialogContent>
			<DialogActions style={{ paddingTop: '0rem' }}>
				<div style={{ margin: 'auto' }}>
					<Button
						type='button'
						color='primary'
						btnText='Add'
						onClick={AddBookmarkName}
					/>
					<Button
						type='button'
						btnText='Cancel'
						onClick={() => setDialogOpen(false)}
						color='secondary'
						variant='outlined'
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
});
export default AddBookmark;
