const InsightsEndPoints = {
	getInsightsQuestions(data: any) {
		return {
			url: 'businessinsights/all_questions?category_id=1',
			data: data.request,
			headers: data.headers,
		};
	},
	getInsightsAnswer(data: any) {
		return {
			url: `businessinsights/question`,
			data: data.request,
			headers: data.headers,
		};
	},
};

export default InsightsEndPoints;
