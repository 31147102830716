export const columns: any = [
	{
		label: 'Campaign',
		value: 'campaign_name',
	},
	{
		label: 'Campaign Group',
		value: 'campaign_group',
	},
	{
		label: 'Status',
		value: 'campaign_status',
	},
	{
		label: 'Type',
		value: 'campaign_type',
	},
	{
		label: 'Platform',
		value: 'platform_name',
	},
	{
		label: 'Impressions',
		value: 'impressions',
	},
	{
		label: 'Clicks',
		value: 'clicks',
	},
	{
		label: 'CTR',
		value: 'ctr',
	},
	{
		label: 'Revenue',
		value: 'revenue',
	},
	{
		label: 'Unique Visitors',
		value: 'unique_visitors',
	},
	{
		label: 'Form Fill',
		value: 'form_fill',
	},
	{
		label: 'Conversion Rate',
		value: 'conversion_rate',
	},
	{
		label: 'Platform Cost',
		value: 'platform_cost',
	},
	{
		label: 'Cost Per Lead',
		value: 'cost_per_lead',
	},
	{
		label: 'Leads',
		value: 'total_leads',
	},
	{
		label: 'CPM',
		value: 'cpm',
	},
	{
		label: 'MQL',
		value: 'mql',
	},
	{
		label: 'SQL',
		value: 'sql',
	},
	{
		label: 'Opportunity',
		value: 'oppty',
	},
	{
		label: 'Total Closed Won',
		value: 'total_closed_won',
	},
	{
		label: 'ACV',
		value: 'acv',
	},
];

export const defaultColumns: any = [
	{
		label: 'Campaign',
		value: 'campaign_name',
	},
	{
		label: 'Platform',
		value: 'platform_name',
	},
	{
		label: 'Platform Cost',
		value: 'platform_cost',
	},
	{
		label: 'Clicks',
		value: 'clicks',
	},
	{
		label: 'Leads',
		value: 'total_leads',
	},
	{
		label: 'Revenue',
		value: 'revenue',
	},
	{
		label: 'Opportunity',
		value: 'oppty',
	},
];
